import { Container } from "@/shared/ui";
import { useEffect } from "react";

const TrainTicketsList = () => {
  useEffect(() => {
    const loadScripts = () => {
      const microWidgetScript = document.createElement("script");
      microWidgetScript.src =
        "https://tripandfly.ru/micro-widget/tripandfly-widget.js";
      microWidgetScript.type = "module";

      const portalScript = document.createElement("script");
      portalScript.src = "https://tripandfly.ru/embedded-portal/embedded.js";
      portalScript.type = "module";

      document.body.appendChild(microWidgetScript);
      document.body.appendChild(portalScript);
    };

    loadScripts();
  }, []);
  return (
    <Container className="mt-10">
      <div>
        <div className="w-full min-h-600">
          <tripandfly-widget partnerid="elistory_wl"></tripandfly-widget>
        </div>
        <div className="w-full min-h-600">
          <wl-embedded-portal partnerid="elistory_wl"></wl-embedded-portal>
        </div>
      </div>
    </Container>
  );
};
export default TrainTicketsList;
